import Rails from "@rails/ujs";
Rails.start();

import Alpine from "alpinejs";
window.Alpine = Alpine;
Alpine.start();

import jQuery from "jquery";
window.$ = jQuery;
window.jQuery = jQuery;

import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

import LocalTime from "local-time";
LocalTime.start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "../uploads";

import "notyf/notyf.min.css";
